<template>
    <div class="app-container">
        <dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed" @toggledCard="toggledCard">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title">ID Feed</div>
                    <el-input v-model="filters.feed_id.value" class="input-with-select fullWidth">
                        <el-button slot="append" icon="el-icon-search" @click="applyFilters"></el-button>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
                    <div class="sub-title">Status</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.status.value"
                        placeholder="Toate"
                        @change="applyFilters"
                    >
                        <el-option label="Toate" value=""></el-option>
                        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
        </dd-card>
        <div class="separator"></div>

        <!--
		<Box :type="'info'" :active="true" v-intro-step="1">
			<div slot="box-body">
				<ul class="rawUl">
					<li><strong>Feedurile trebuie sa contina cel putin 30 produse pentru ca sistemul sa extraga corect campurile pentru asociere.</strong></li>
				</ul>
			</div>
		</Box>
		-->

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Lista feeduri</span>
                <router-link :to="{ path: '/publicare/feed' }">
                    <el-button style="float:right" type="primary" icon="el-icon-plus" plain>Adauga feed</el-button>
                </router-link>
            </div>
            <div class="body">
                <el-alert v-if="runRequestId !== ''" class="run-confirmation" type="success"
                    >A fost inregistrata cererea cu ID <strong>{{ runRequestId }}</strong> pentru rularea feedului.
                    Pentru a verifica rezultatul rularii, introduceti ID-ul cererii in pagina cu istoricul
                    feedului.</el-alert
                >
                <div class="separator"></div>
                <el-table :data="feedsList" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="75px"> </el-table-column>
                    <el-table-column prop="feed_name" label="Feed">
                        <template slot-scope="scope">
                            <div>{{ scope.row.feed_name }}</div>
                            <div>
                                <a :href="scope.row.feed_url" target="_blank"
                                    ><strong>{{ scope.row.feed_url | truncate(50, '...') }}</strong></a
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="Status" width="100px">
                        <template slot-scope="scope">
                            <el-tag size="small" type="danger" v-if="!isFeedActive(scope.row.active)">Inactiv</el-tag>
                            <el-tag
                                size="small"
                                type="warning"
                                v-else-if="isFeedActive(scope.row.active) && scope.row.errors_at_parse > 0"
                                >Activ</el-tag
                            >
                            <el-tag size="small" type="success" v-else-if="isFeedActive(scope.row.active)"
                                >Activ</el-tag
                            >
                        </template>
                    </el-table-column>
                    <el-table-column prop="import_type" label="Tip feed" width="200px">
                        <template slot-scope="scope">
                            <div>{{ getFeedImportType(scope.row.import_type) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="last_run" label="Ultima rulare" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.last_run != null ? scope.row.last_run : 'N/A' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="feed_name" label="Actiuni" width="250px">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="Editeaza" placement="top">
                                <router-link :to="{ path: '/publicare/feed/' + scope.row.id }" class="action-button">
                                    <el-button title="Editeaza" size="mini" type="primary">
                                        <svg-icon icon-class="wrench" />
                                    </el-button>
                                </router-link>
                            </el-tooltip>
                            <el-tooltip
                                v-if="!isFeedActive(scope.row.active)"
                                class="item"
                                effect="dark"
                                content="Activeaza"
                                placement="top"
                            >
                                <el-button
                                    title="Activeaza"
                                    size="mini"
                                    type="success"
                                    class="action-button"
                                    @click="changeFeedStatus(scope.row.id, 1)"
                                >
                                    <svg-icon icon-class="play-button" />
                                </el-button>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="dark" content="Dezactiveaza" placement="top">
                                <el-button
                                    title="Dezactiveaza"
                                    size="mini"
                                    type="danger"
                                    class="action-button"
                                    @click="changeFeedStatus(scope.row.id, 0)"
                                >
                                    <svg-icon icon-class="stop" />
                                </el-button>
                            </el-tooltip>
                            <el-tooltip
                                v-if="feedCompleted(scope.row)"
                                class="item"
                                effect="dark"
                                content="Ruleaza feed"
                                placement="top"
                            >
                                <el-button
                                    title="Ruleaza feed"
                                    size="mini"
                                    type="primary"
                                    class="action-button"
                                    @click="runFeed(scope.row.id)"
                                >
                                    <svg-icon icon-class="setting-gears" />
                                </el-button>
                            </el-tooltip>
                            <el-tooltip
                                v-else
                                class="item"
                                effect="dark"
                                content="Feed incomplet. Nu puteti rula feedul"
                                placement="top"
                            >
                                <el-button
                                    :disabled="true"
                                    size="mini"
                                    type="danger"
                                    class="action-button"
                                    @click="changeFeedStatus(scope.row.id, 0)"
                                >
                                    <svg-icon icon-class="setting-gears" />
                                </el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Istoric rulare feed" placement="top">
                                <router-link :to="{ path: '/publicare/istoric-feed/' + scope.row.id }">
                                    <el-button size="mini" type="info" class="action-button" icon="el-icon-time">
                                    </el-button>
                                </router-link>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import Vue from 'vue';
const DDCard = () => import('@/components/DDCard');
import { mapGetters } from 'vuex';
import { filter } from '@/utils/index';
Vue.filter('truncate', filter);

import { createFeedsStore } from '@/store/modules/feeds/index.js';

export default {
    components: {
        'dd-card': DDCard
    },
    props: ['id', 'filterQuery'],
    data() {
        return {
            isCollapsed: true,
            runRequestId: '',
            filters: {
                status: {
                    type: 'select',
                    value: ''
                },
                start: {
                    type: 'page',
                    value: 0
                },
                feed_id: {
                    type: 'input',
                    value: ''
                }
            },
            statusList: [
                {
                    value: '0',
                    label: 'Inactiv'
                },
                {
                    value: '1',
                    label: 'Activ'
                }
            ],
            feedModels: [
                {
                    label: 'Publicare produse',
                    value: 1
                },
                {
                    label: 'Actualizare pret si stoc',
                    value: 0
                }
            ]
        };
    },
    methods: {
        toggledCard() {
            this.isCollapsed = !this.isCollapsed;
        },
        getFeeds() {
            const params = {};
            for (const n in this.filters) {
                if (this.filters[n].value != null && this.filters[n].value !== '') params[n] = this.filters[n].value;
            }
            this.$store.dispatch('feeds/GetFeeds', params);
        },
        isFeedActive(status) {
            return parseInt(status) > 0;
        },
        changeFeedStatus(feedId, status) {
            this.$store
                .dispatch('feeds/ChangeFeedStatus', {
                    id: feedId,
                    status
                })
                .then((res) => {
                    if (typeof res.message !== 'undefined' && typeof res.message.success !== 'undefined') {
                        this.getFeeds();
                    }
                });
        },
        feedCompleted(feed) {
            return parseInt(feed.import_type) === 0 ? parseInt(feed.feed_status) >= 2 : parseInt(feed.feed_status) >= 3;
        },
        runFeed(id) {
            this.$confirm(
                'Siguri doriti sa rulati feedul? Puteti rula manual feedul maxim o data pe ora.',
                this.$t('general.warning'),
                {
                    confirmButtonText: this.$t('general.yes'),
                    cancelButtonText: this.$t('general.no'),
                    type: 'warning'
                }
            )
                .then(() => {
                    this.$store
                        .dispatch('feeds/RunFeed', {
                            id
                        })
                        .then((res) => {
                            if (
                                typeof res.message !== 'undefined' &&
                                typeof res.message.done !== 'undefined' &&
                                typeof res.message.request_id !== 'undefined'
                            ) {
                                this.runRequestId = res.message.request_id;
                            }
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'RunFeed',
                                    parmas: {
                                        id
                                    }
                                }
                            });
                            console.log(e);
                        });
                })
                .catch((err) => {
                    return false;
                });
        },
        applyFilters() {
            if (typeof this.updateQuery === 'function') this.updateQuery();
            this.getFeeds();
        },
        getFeedImportType(type) {
            const typeObj = this.feedModels.find((item) => item.value === parseInt(type));
            return typeObj != null ? typeObj.label : 'Incomplet';
        }
    },
    computed: {
        ...mapGetters({
            feedsList: 'feeds/feedsList'
        }),
        haveFeeds() {
            return typeof feedsList.feeds !== 'undefined' ? feedsList.feeds : [];
        }
    },
    beforeCreate() {
        this.$store.registerModule('feeds', createFeedsStore());
    },
    mounted() {
        this.getFeeds();
    },
    beforeDestroy() {
        this.$store.unregisterModule('feeds');
    }
};
</script>

<style lang="scss" scoped>
.feed-step-1 {
    &.subtitle {
        font-size: 12px;
        display: inline-block;
        position: relative;
        width: 100%;
        color: #606266;
    }
}

.action-button {
    margin: 0px 7px 0px 0px;
}

.run-confirmation {
    font-size: 14px;
}
</style>
